<template>
  <div>
    <v-container fluid>


      <v-row>
        <v-col cols="12" lg="4">
          <v-card :loading="isLoading" class="shadow rounded-lg">
            <v-card-text class="text-center">

              <div>

                <v-avatar size="70">
                  <v-img v-if="user.account.photo" :src="getImageLink(user.account.photo)"></v-img>
                  <v-img v-else :src="require('@/assets/avatar.png')"></v-img>
                </v-avatar>

                <div class="text-center py-4 cursor-pointer"
                     @click="onButtonClick">
                  <v-btn :loading="isLoading"
                         color="gifty"
                         outlined
                         rounded
                  >
                    <v-icon color="gifty" left>mdi-cloud-upload</v-icon>
                    Modifier la photo
                  </v-btn>
                  <input ref="uploader"
                         accept="image/png,image/jpeg"
                         class="d-none"
                         type="file"
                         @change="uploadPhoto($event)">
                </div>

                <v-alert v-if="errors"
                         border="left"
                         dense
                         dismissible
                         text
                         type="error">
                  <ul>
                    <li v-for="(err,i) in errors" :key="i">{{ err.toString() }}</li>
                  </ul>
                </v-alert>

                <span class="mb-3 d-block">
                 <span class="font-weight-medium">Nom complet :</span>
                  {{ user.name }}
                </span>

                <span class="mb-3 d-block">
                   <span class="font-weight-medium"> Nom d'utilisateur :</span>
                  {{ user.username }}
                </span>

                <span class="d-block">
                   <span class="font-weight-medium"> Email :</span>
                  {{ user.email }}
                </span>

                <v-btn class="v-btn--active mt-3" color="success" rounded text>
                  {{ CurrencyFormatting($store.state.user.user.account.all_balance) }} DZD
                </v-btn>

              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="8">
          <v-card class="shadow rounded-lg">

            <v-toolbar flat>
              <v-tabs v-model="tabs"
                      centered
                      color="gifty"
                      mobile-breakpoint="1"
                      next-icon="mdi-arrow-right"
                      prev-icon="mdi-arrow-left">
                <v-tab>Mofifier mon compte</v-tab>
                <v-tab>Mofifier le mot de passe</v-tab>
              </v-tabs>
            </v-toolbar>

            <v-tabs-items v-model="tabs">

              <v-tab-item>
                <v-card flat>
                  <v-card-text class="pa-6">
                    <AccountForm/>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text class="pa-6">
                    <PasswordForm/>
                  </v-card-text>
                </v-card>
              </v-tab-item>

            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import PasswordForm from "./PasswordForm";
import AccountForm from "./AccountForm";
import {HTTP} from "@/http-common";

export default {
   components: {AccountForm, PasswordForm},
   data() {
      return {
         isLoading: false,
         errors: null,
         tabs: null,
      }
   },
   methods: {
      uploadPhoto(file) {
         this.isLoading = true
         this.errors = null
         let formData = new FormData()
         formData.append('photo', file.target.files[0])
         HTTP.post('/account/update-profile-picture', formData).then((res) => {
            this.isLoading = false
            this.$store.commit('updateUserPicture', res.data.photo)
            this.$successMessage = 'Votre photo a été modifié avec succès !'
         }).catch(err => {
            this.isLoading = false
            this.errors = err.response.data.errors
            console.log(err)
         })
      },
      onButtonClick() {
         window.addEventListener('focus', () => {
         }, {once: true})
         this.$refs.uploader.click()
      },
   },
   computed: {
      user() {
         return this.$store.state.user.user
      },
   }
}
</script>

<style scoped>

</style>