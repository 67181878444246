<template>
  <div>
    <form @submit.prevent="save">

      <v-text-field :error-messages="errors.current_password"
                    v-model.trim="form.current_password"
                    label="Mot de passe actuel *"/>

      <v-text-field :type="show ? 'text' : 'password'"
                    v-model.trim="form.new_password"
                    :error-messages="errors.new_password"
                    @click:append="show = !show"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Nouveau mot de passe *"/>

      <v-text-field
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :error-messages="errors.confirm_password"
          v-model.trim="form.confirm_password"
          label="Confirmer le nouveau mot de passe *"/>


      <v-alert text color="info" class="mt-5">
        <p>
          Votre mot de passe doit contenir les éléments suivant :
        </p>
        <span class="d-block">8 Caractère minimum</span>
        <span class="d-block">1 Majuscule / minuscule</span>
        <span class="d-block">1 Chiffre</span>
        <span class="d-block">1 Caractere spécial (@,& ...)</span>
      </v-alert>


      <div class="text-right mt-5">
        <v-btn :loading="btnLoading"
               type="submit"
               depressed
               color="primary">
          <v-icon left>mdi-content-save</v-icon>
          Enregistrer
        </v-btn>
      </div>

    </form>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      errors: {},
      form: {},
      show: false,
      btnLoading: false
    }
  },
  methods: {
    save() {
      this.btnLoading = true
      this.errors = {}
      HTTP.post('/auth/update-password', this.form).then(() => {
        this.btnLoading = false
        this.$successMessage = 'Votre mot de passe a été modifié avec succés.'
        this.form = {}
      }).catch(err => {
        this.errors = err.response.data.errors
        this.btnLoading = false
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>