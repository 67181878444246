<template>
  <div>
    <form @submit.prevent="save">

      <v-text-field :error-messages="errors.name"
                    v-model="form.name"
                    label="Nom complet *"/>

      <v-text-field :error-messages="errors.username"
                    v-model="form.username"
                    label="Nom d'utilisateur *"/>

      <v-text-field :error-messages="errors.email"
                    v-model="form.email"
                    label="Email *"/>

      <div class="text-right mt-5">
        <v-btn :loading="btnLoading"
               :disabled="btnLoading"
               type="submit"
               depressed
               color="primary">
          <v-icon left>mdi-content-save</v-icon>
          Enregistrer
        </v-btn>
      </div>

    </form>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      errors: {},
      form: {
        name: this.$store.state.user.user.name,
        username: this.$store.state.user.user.username,
        email: this.$store.state.user.user.email,
      },
      btnLoading: false
    }
  },
  methods: {
    save() {
      this.btnLoading = true
      this.errors = {}
      HTTP.post('/auth/update-account', this.form).then(() => {
        this.btnLoading = false

        let data = {
          name: this.form.name,
          username: this.form.username,
          email: this.form.email,
        }
        this.$store.commit('updateUserAccount', data)

        this.$successMessage = 'Votre compte a été modifié avec succés.'

      }).catch(err => {
        this.errors = err.response.data.errors
        this.btnLoading = false
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>